<div class="bg-white px-4 py-0 sm:flex-nowrap sm:px-6 lg:px-8" *ngIf="customer">

  <!-- Breadcrumbs -->
  <nav class="flex pb-8" aria-label="Breadcrumb">
    <ol role="list" class="flex items-center space-x-4">
      <li>
        <div>
          <a routerLink="/" class="text-gray-400 hover:text-gray-500 navIcon">
            <svg class="h-5 w-5 flex-shrink-0" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd"
                d="M9.293 2.293a1 1 0 011.414 0l7 7A1 1 0 0117 11h-1v6a1 1 0 01-1 1h-2a1 1 0 01-1-1v-3a1 1 0 00-1-1H9a1 1 0 00-1 1v3a1 1 0 01-1 1H5a1 1 0 01-1-1v-6H3a1 1 0 01-.707-1.707l7-7z"
                clip-rule="evenodd" />
            </svg>
            <span class="sr-only">Home</span>
          </a>
        </div>
      </li>
      <li>
        <div class="flex items-center">
          <svg class="h-5 w-5 flex-shrink-0 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fill-rule="evenodd"
              d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
              clip-rule="evenodd" />
          </svg>
          <a routerLink="/customers"
            class="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700 navIcon">Customers</a>
        </div>
      </li>
      <li>
        <div class="flex items-center">
          <svg class="h-5 w-5 flex-shrink-0 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fill-rule="evenodd"
              d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
              clip-rule="evenodd" />
          </svg>
          <a class="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700 navIcon-last"
            aria-current="page">{{customer.organisationName}}</a>
        </div>
      </li>
    </ol>
  </nav>


  <div class="items-center gap-6 mt-3">
    <h1 class="font-semibold text-xl leading-7 text-gray-900">{{customer.organisationName}}</h1>

    <div class="mt-2">
      <p class="text-xs text-gray-500" *ngIf="customer.createdAt">Safer-Track customer since {{customer.createdAt |
        date}}</p>
      <div *ngIf="customer.annualContract"
        class="whitespace-nowrap text-sm text-emerald-400 flex items-center pt-1 leading-normal">
        <ng-icon name="heroCheckCircle" class="mr-1 text-emerald-400" size="18"></ng-icon>
        <span>Annual Contract</span>
      </div>
    </div>
  </div>


  <!-- Customer Detail -->
  <div>
    <div class="grid grid-cols-12">

      <!-- Customer Info -->
      <div class="col-span-4 mt-6">
        <dl>
          <div class="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3 min-h-24">
            <dt class="text-sm font-medium leading-6 text-gray-900">Organisation Name</dt>
            <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              <p>{{customer.organisationName}}</p>
            </dd>
          </div>
          <div class="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
            <dt class="text-sm font-medium leading-6 text-gray-900">Address</dt>
            <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              <span class="block font-medium">{{customer.address.address_line_1}}</span>
              <span class="block">{{customer.address.town}}, {{customer.address.postcode}}</span>
            </dd>
          </div>
        </dl>
      </div>

      <!-- Contact Manager -->
      <div class="col-span-4 mt-6">
        <dl>
          <div class="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3 min-h-24">
            <dt class="text-sm font-medium leading-6 text-gray-900">Primary Contact</dt>
            <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              <p class="">{{customer.primaryContact && customer.primaryContact.name_first}} {{customer.primaryContact && customer.primaryContact.name_last}}</p>
              <p class="text-xs">{{ customer.primaryContact && customer.primaryContact.email}}</p>
            </dd>
          </div>
          <div class="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
            <dt class="text-sm font-medium leading-6 text-gray-900">Edit</dt>
            <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 flex flex-col">
              <button type="button" (click)="openManageContactDialog()"
                class="action-button inline-flex items-center  border border-transparent text-sm leading-4 font-medium rounded-md text-cg-primary bg-cg-primary-light hover:bg-cg-primary-dark focus:outline-none focus:shadow-outline-cg-primary active:bg-cg-primary-dark transition ease-in-out duration-150">
                <ng-icon name="heroUserCircle" class="mr-1"></ng-icon>
                <span>Manage contacts</span>
              </button>
              <button type="button" (click)="openManageEditDialog()"
                class="action-button inline-flex items-center  border border-transparent mt-1 text-sm leading-4 font-medium rounded-md text-cg-primary bg-cg-primary-light hover:bg-cg-primary-dark focus:outline-none focus:shadow-outline-cg-primary active:bg-cg-primary-dark transition ease-in-out duration-150">
                <ng-icon name="heroPencil" class="mr-1"></ng-icon>
                <span>Edit customer info</span>
              </button>
            </dd>
          </div>
        </dl>
      </div>

      <!-- Customer Settings -->
      <div class="col-span-4 p-5 mt-6">
        <fieldset>
          <legend class="sr-only">Settings
          </legend>
          <div class="space-y-5">
            <div class="relative flex items-start">
              <div class="flex h-6 items-center">
                <input id="comments" aria-describedby="comments-description" name="comments" type="checkbox"
                  class="h-4 w-4 rounded border-gray-300 text-cg-primary focus:ring-cg-primary"
                  [checked]="customer.annualContract" (change)="toggleAnnualContract($event)" #annualContract>
              </div>
              <div class="ml-3 text-sm leading-6">
                <label for="comments" class="font-medium text-gray-900">Annual contract</label>
                <p id="comments-description" class="text-gray-500">Customer has annual audit contract.</p>
              </div>
            </div>
            <div class="relative flex items-start">
              <div class="flex h-6 items-center">
                <input id="candidates" aria-describedby="candidates-description" name="candidates" type="checkbox"
                  class="h-4 w-4 rounded border-gray-300 text-cg-primary focus:ring-cg-primary"
                  [checked]="customer.automaticReminders" (change)="toggleAutomaticReports($event)" #automaticReports>
              </div>
              <div class="ml-3 text-sm leading-6">
                <label for="candidates" class="font-medium text-gray-900">Automatic reminders</label>
                <p id="candidates-description" class="text-gray-500">Send automatic reminders to customer.</p>
              </div>
            </div>
            <div class="relative flex items-start">
              <div class="flex h-6 items-center">
                <input id="offers" aria-describedby="offers-description" name="offers" type="checkbox"
                  class="h-4 w-4 rounded border-gray-300 text-cg-primary focus:ring-cg-primary"
                  [checked]="customer.enforceAssetIDOnTracks == true" (change)="toggleEnforceAssetTracking($event)"
                  #enforceAssetTracking />
              </div>
              <div class="ml-3 text-sm leading-6">
                <label for="offers" class="font-medium text-gray-900">Enforce asset tracking</label>
                <p id="offers-description" class="text-gray-500">Asset IDs must be recorded in audits.</p>
              </div>
            </div>
          </div>
        </fieldset>
      </div>
    </div>

  </div>

  <div class="relative">
    <div class="absolute inset-0 flex items-center" aria-hidden="true">
      <div class="w-full border-t border-gray-300"></div>
    </div>
  </div>

  <!-- Contracts -->
  <div>
    <div class="px-4 pt-5 sm:px-0">
      <h3 class="text-base font-semibold leading-7 text-gray-900">Contracts</h3>
      <p class="mt-1 max-w-2xl text-sm leading-6 text-gray-500">{{customer.organisationName}}'s contracts.
      </p>
    </div>

    <!-- Updated Table -->
    <div>
      <div class="flex justify-between py-5 sm:flex-nowrap">
        <div class="relative max-w-3xl">
          <div class="absolute inset-y-0 start-0 flex items-center ps-2 pointer-events-none">
            <svg class="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
              fill="none" viewBox="0 0 20 20">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
            </svg>
          </div>
          <input type="search" id="default-search" [formControl]="filterInput"
            class="block w-full p-2 ps-8 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-cg-primary focus:border-sky-950 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Filter..." required />
        </div>
        <button (click)="createContract()"
          class="ml-auto flex items-center gap-x-1 rounded-md bg-cg-primary px-2 py-1 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
          <ng-icon name='heroPlus' size='18' class='text-white'></ng-icon>
          New Contract
        </button>
      </div>

      <table cdk-table [dataSource]="contracts" cdkSort class="min-w-full divide-y divide-gray-300 bg-white">
        <!-- Name Column -->
        <ng-container cdkColumnDef="name">
          <th cdk-header-cell *cdkHeaderCellDef cdk-sort-header
            class="py-3.5 text-left text-sm font-semibold text-gray-900 w-96">Name</th>
          <td cdk-cell *cdkCellDef="let contract" class="align-top py-3">
            <a routerLink="/contract/{{contract._id}}" class="text-sm font-medium text-gray-700 leading-normal">{{
              contract.name }}</a>
          </td>
        </ng-container>

        <!-- Status Column -->
        <ng-container cdkColumnDef="status">
          <th cdk-header-cell *cdkHeaderCellDef cdk-sort-header
            class="py-3.5 text-left text-sm font-semibold text-gray-900">Status</th>
          <td cdk-cell *cdkCellDef="let contract" class="align-top py-3">
            <div class="flex items-center">
              <ng-container *ngIf="contract.status === 'Invalid'">
                <ng-icon name="heroXCircle" class="mr-1 text-red-500" size="18"></ng-icon>
                <span class="text-red-500 font-medium text-sm">{{ parseStatus(contract.status) }}</span>
              </ng-container>
              <ng-container *ngIf="contract.status === 'Valid'">
                <ng-icon name="heroCheckCircle" class="mr-1 text-green-500" size="18"></ng-icon>
                <span class="text-green-500 font-medium text-sm">{{ parseStatus(contract.status) }}</span>
              </ng-container>
              <ng-container *ngIf="contract.status === 'Inspection Due'">
                <ng-icon name="heroExclamationCircle" class="mr-1 text-yellow-500" size="18"></ng-icon>
                <span class="text-yellow-500 font-medium text-sm">{{ parseStatus(contract.status) }}</span>
              </ng-container>
              <ng-container *ngIf="contract.status === 'No Data'">
                <ng-icon name="heroQuestionMarkCircle" class="mr-1 text-gray-500" size="18"></ng-icon>
                <span class="text-gray-700 font-medium text-sm">{{ parseStatus(contract.status) }}</span>
              </ng-container>
            </div>
            <span class="block text-xs pt-1 text-gray-500">
              {{contract.daysToExpiry && contract.daysToExpiry < 0 ? ' (expired ' + absolute(contract.daysToExpiry)
                + ' days ago)' : '' }} {{contract.daysToExpiry && contract.daysToExpiry> 0 ? ' (valid for ' +
                contract.daysToExpiry + ' days)' : ''}}
                {{contract.daysToExpiry && contract.daysToExpiry == 0 ? ' (expires today)' : ''}}
            </span>
          </td>
        </ng-container>

        <!-- Last Inspection Column -->
        <ng-container cdkColumnDef="lastInspection">
          <th cdk-header-cell *cdkHeaderCellDef cdk-sort-header
            class="py-3.5 text-left text-sm font-semibold text-gray-900">Last Inspection</th>
          <td cdk-cell *cdkCellDef="let contract" class="align-top py-3">
            <div *ngIf="contract.lastInspectionData">
              <a routerLink="/inspections/{{contract.lastInspectionData._id}}" class="text-sm text-cg-primary">
                <span *ngIf="contract.lastInspectionData.internalReference">{{
                  contract.lastInspectionData.internalReference }}</span>
                <span *ngIf="!contract.lastInspectionData.internalReference">{{ contract.lastInspectionData.reference
                  }}</span>
              </a>
              <span class="block text-xs text-gray-500">{{ contract.lastInspectionData.date | date }}</span>
            </div>
            <span *ngIf="!contract.lastInspectionData" class="text-sm text-gray-500">No data</span>
          </td>
        </ng-container>

        <!-- Contact Column -->
        <ng-container cdkColumnDef="contact">
          <th cdk-header-cell *cdkHeaderCellDef class="py-3.5 text-left text-sm font-semibold text-gray-900">
            Contact</th>
          <td cdk-cell *cdkCellDef="let contract" class="align-top py-3">
            <div *ngIf="contract.primaryContact" class="text-sm text-gray-700 font-medium leading-normal">
              <p>{{contract.primaryContact.name_first}}
                {{contract.primaryContact.name_last}}</p>
              <div class="flex items-center space-x-1 mt-1 leading-normal text-sm">
                <ng-icon name="heroAtSymbol" class="text-gray-500" size="14"></ng-icon>
                <span>{{ contract.primaryContact.email }}</span>
              </div>
            </div>
            <div *ngIf="!contract.primaryContact" class="text-sm text-gray-500">No Contact</div>
          </td>
        </ng-container>

        <!-- Created Date Column -->
        <ng-container cdkColumnDef="createdAt">
          <th cdk-header-cell *cdkHeaderCellDef cdk-sort-header
            class="py-3.5 text-left text-sm font-semibold text-gray-900">Created</th>
          <td cdk-cell *cdkCellDef="let contract" class="align-top py-3">
            <span class="text-sm text-gray-700 font-medium leading-normal">{{ contract.createdAt | date }}</span>
          </td>
        </ng-container>

        <!-- Actions Column -->
        <ng-container cdkColumnDef="actions">
          <th cdk-header-cell *cdkHeaderCellDef class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"></th>
          <td cdk-cell *cdkCellDef="let contract" class="align-top py-3 flex justify-end">
            <button cdk-icon-button [matMenuTriggerFor]="additionalMenu"
              class="action-button hover:bg-gray-200 rounded-full">
              <span class="sr-only">Open options</span>
              <ng-icon name="heroEllipsisVertical" size="18" class="text-gray-500"></ng-icon>
            </button>

            <mat-menu #additionalMenu="matMenu" class="inline-flex flex-col min-w-40 bg-white rounded-md">
              <button cdk-menu-item (click)="deleteContract(contract)"
                class="action-button px-3 py-3 text-sm leading-6 text-gray-900 hover:bg-gray-100 flex flex-row items-center justify-center">
                <ng-icon name="heroTrash" class="text-red-500 mr-2" size="18"></ng-icon>

                Delete Contract
              </button>
              <button cdk-menu-item (click)="openMoveContractToNewCustomerDialog(contract._id)"
                class="action-button px-3 py-3 text-sm leading-6 text-gray-900 hover:bg-gray-100 flex flex-row items-center justify-center">
                <ng-icon name="heroArrowsUpDown" class="text-cg-primary mr-2" size="18"></ng-icon>

                Move Contract
              </button>
              <button cdk-menu-item (click)="archiveContract(contract._id)"
                class="action-button px-3 py-3 text-sm leading-6 text-gray-900 hover:bg-gray-100 flex flex-row items-center justify-center">
                <ng-icon name="heroArchiveBox" class="text-gray-500 mr-2" size="18"></ng-icon>
                Archive contract
              </button>
            </mat-menu>
          </td>
        </ng-container>

        <!-- Rows -->
        <tr cdk-header-row *cdkHeaderRowDef="displayedColumns"></tr>
        <tr cdk-row *cdkRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
  </div>

  <!-- Archived Contracts -->
  <div *ngIf="customer.archivedContracts.length > 0">
    <hr class="mt-5 border-t border-gray-200" />

    <cdk-accordion class="example-accordion">
      <cdk-accordion-item #accordionItem="cdkAccordionItem" class="example-accordion-item" role="button" tabindex="0">
        <div (click)="accordionItem.toggle()">
          <div class="px-4 pt-5 sm:px-0 hover:bg-gray-50 rounded-md">
            <h3 class="text-base font-semibold leading-7 text-gray-900">Archived Contracts</h3>
            <p class="mt-1 max-w-2xl text-sm leading-6 text-gray-500">Click here to view archived contracts.</p>
          </div>
        </div>

        <div class="example-accordion-item-body" role="region" [style.display]="accordionItem.expanded ? '' : 'none'">
          <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <table class="min-w-full divide-y divide-gray-300">

                <thead>
                  <tr>
                    <th scope="col"
                      class="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                      Name
                    </th>
                    <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Inspections
                    </th>
                    <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Created
                    </th>
                    <th scope="col" class="relative whitespace-nowrap py-3.5 pl-3 pr-4 sm:pr-0">
                      <span class="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>

                <tbody class="divide-y divide-gray-200 bg-white">
                  <tr *ngFor="let contract of customer.archivedContracts">
                    <td class="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-900 sm:pl-0">{{contract.name}}</td>
                    <td class="whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-900">
                      {{contract.safetyInspections.length}}
                    </td>
                    <td class="whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-900">
                      {{contract.createdAt | date }}
                    </td>
                    <td class="whitespace-nowrap py-2 text-right text-sm text-gray-500">
                      <button (click)="unarchiveContract(contract)">Unarchive</button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </cdk-accordion-item>
    </cdk-accordion>
  </div>
</div>
